<template>
    <footer>
        <div class="box">

            <div class="wxbox">
                <ul>
<!--                    <li><img src="@/assets/images/qqq.jpg"><span>欢迎加入qq群</span></li>-->
                    <li><img src="@/assets/images/wx.png"><span>我的微信</span></li>
                </ul>
            </div>
            <div class="endnav">
                <p><b>站点声明：</b></p>
                <p>1、本站内容转发请注明来源 </p>
                <p>Copyright © 2022 </p>
                <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022009676号-1</a>
            </div>
        </div>
        <a href="#">
            <div class="top"></div>
        </a> </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>