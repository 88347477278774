<template>
    <div class="banbox ">
        <swiper class="swiper"  style="height: 260px;" >
            <swiper-slide v-for="(item,index) in sliders" :key="index">
                <a :href="item.url" target="_blank">
                  <img :src="item.img" width="100%" height="100%" :alt="item.text">
                  <span style="position: absolute; top: 35%; left: 20%; right:20%; font-size:30px; text-align:center;" >{{item.text}}</span>
                </a>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

    import 'swiper/swiper-bundle.css'
    export default {
        name: "FSwiper",
        components:{
            Swiper,SwiperSlide
        },
        data(){
            return{
                sliders:[
                    {
                        "img": "https://www.item.whiteonion.cn/zixinzhi.png",
                        "url":"https://space.bilibili.com/5552611/",
                        "text":"B站关注不迷路"
                    }

                ],

            }
        }
    }
</script>

<style scoped>

</style>